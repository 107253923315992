@font-face {
  font-family: 'roboto';
  font-style: normal;
  font-weight: 100;
  src: url("../assets/fonts/roboto/Roboto-Thin-webfont.woff") format("woff");
}

@font-face {
  font-family: 'roboto';
  font-style: italic;
  font-weight: 100;
  src: url("../assets/fonts/roboto/Roboto-ThinItalic-webfont.woff") format("woff");
}

@font-face {
  font-family: 'roboto';
  font-style: normal;
  font-weight: 200;
  src: url("../assets/fonts/roboto/Roboto-Light-webfont.woff") format("woff");
}

@font-face {
  font-family: 'roboto';
  font-style: italic;
  font-weight: 200;
  src: url("../assets/fonts/roboto/Roboto-LightItalic-webfont.woff") format("woff");
}

@font-face {
  font-family: 'roboto';
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/roboto/Roboto-Regular-webfont.woff") format("woff");
}

@font-face {
  font-family: 'roboto';
  font-style: italic;
  font-weight: 400;
  src: url("../assets/fonts/roboto/Roboto-Italic-webfont.woff") format("woff");
}

@font-face {
  font-family: 'roboto';
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/roboto/Roboto-Medium-webfont.woff") format("woff");
}

@font-face {
  font-family: 'roboto';
  font-style: italic;
  font-weight: 500;
  src: url("../assets/fonts/roboto/Roboto-MediumItalic-webfont.woff") format("woff");
}

@font-face {
  font-family: 'roboto';
  font-style: normal;
  font-weight: 600;
  src: url("../assets/fonts/roboto/Roboto-Bold-webfont.woff") format("woff");
}

@font-face {
  font-family: 'roboto';
  font-style: italic;
  font-weight: 600;
  src: url("../assets/fonts/roboto/Roboto-BoldItalic-webfont.woff") format("woff");
}

@font-face {
  font-family: 'roboto';
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/roboto/Roboto-Black-webfont.woff") format("woff");
}

@font-face {
  font-family: 'roboto';
  font-style: italic;
  font-weight: 700;
  src: url("../assets/fonts/roboto/Roboto-BlackItalic-webfont.woff") format("woff");
}


@font-face {
  font-family: 'roboto-condensed';
  font-style: normal;
  font-weight: 200;
  src: url("../assets/fonts/roboto/RobotoCondensed-Light-webfont.woff") format("woff");
}

@font-face {
  font-family: 'roboto-condensed';
  font-style: italic;
  font-weight: 200;
  src: url("../assets/fonts/roboto/RobotoCondensed-LightItalic-webfont.woff") format("woff");
}

@font-face {
  font-family: 'roboto-condensed';
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/roboto/RobotoCondensed-Regular-webfont.woff") format("woff");
}

@font-face {
  font-family: 'roboto-condensed';
  font-style: italic;
  font-weight: 400;
  src: url("../assets/fonts/roboto/RobotoCondensed-Italic-webfont.woff") format("woff");
}

@font-face {
  font-family: 'roboto-condensed';
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/roboto/RobotoCondensed-Medium-webfont.woff") format("woff");
}

@font-face {
  font-family: 'roboto-condensed';
  font-style: italic;
  font-weight: 500;
  src: url("../assets/fonts/roboto/RobotoCondensed-MediumItalic-webfont.woff") format("woff");
}


