@import "copic";
@import "roboto";
@import "reboot";
@import "forkawesome/fork-awesome.scss";
@import "autocomplete";

$lightBlue: #E7EEFC;
$darkGrey: #535476;
$lightGrey: #9699A9;
$ultraLightGrey: #ddd;
$red: #FF6F91;
$blue: #3157E3;

* {
  font-family: 'roboto';
}

body {
  background-color: #f5f5f5;
  padding-top: 0px;
}

.pb-10 { padding-bottom: 10px; }
.pr-20 { padding-right: 20px !important; }

.mr-2 { margin-right: 2px; }

.fs-11 { font-size: 11px; }
.fs-12 { font-size: 12px; }
.fs-13 { font-size: 13px; }
.fs-14 { font-size: 14px; }
.fs-15 { font-size: 15px; }
.fs-16 { font-size: 16px; }
.fs-17 { font-size: 17px; }
.fs-18 { font-size: 18px; }
.fs-19 { font-size: 19px; }
.fs-20 { font-size: 20px; }
.fs-21 { font-size: 21px; }

.txt-center { text-align: center; }

.txt-darkgrey { color: $CN6 !important; }
.txt-grey { color: $CN4 !important; }
.txt-lightgrey { color: $lightGrey !important; }
.txt-ultralightgrey { color: $ultraLightGrey !important; }
.txt-bold { font-weight: 600 !important; }
.txt-red { color: $red !important; }
.txt-lightblue { color: darken( $lightBlue, 20% ) !important; }
.txt-blue { color: $blue !important; }

.lh-12 { line-height: 12px; }

.w-100 { width: 100px; }
.w-150 { width: 150px; }
.w-200 { width: 200px; }

.middle-valign { vertical-align: middle !important; }

h1 {
  font-size: 35px;
  font-weight: 200;
}
h2 {
  font-size: 30px;
  font-weight: 200;
}
h3 {
  font-size: 25px;
  font-weight: 200;
}
h4 {
  font-size: 20px;
  font-weight: 200;
}

.lock {
  position: fixed;
  z-index: 1000;
  right: 10px;
  top: 10px;
  span {
    display: inline-block;
    font-size: 14px;
    padding-right: 10px;
  }
  a {
    display: inline-block;
    padding-left: 8px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    font-size: 18px;
    background-color: $CB14;
    color: #fff;
    transition: all .15s ease-in-out;
    &:hover {
      text-decoration: none;
      background-color: darken($CB14, 10%);
    }
  }
}

.menu-button {
  position: fixed;
  z-index: 1000;
  left: 10px;
  top: 10px;
  a {
    display: inline-block;
    padding-left: 7px;
    padding-top: 3px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    font-size: 14px;
    background-color: $CB14;
    color: #fff;
    transition: all .15s ease-in-out;
    &:hover {
      text-decoration: none;
      background-color: darken($CB14, 10%);
    }
  }
}

.menu-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  text-align: center;
  background-color: #eee;
  border-bottom: 1px solid #aaa;
  z-index: 100;
  padding-top: 10px;
}

.forms-list {
  h1 {
    text-align: center;
  }
  ul {
    list-style-type: none;
    text-align: center;
    padding: 0;
    margin-top: 20px;
    li {
      padding-bottom: 5px;
      cursor: pointer;
      width: 100%;
      display: inline-block;
      color: $CW10;
      outline: none;
      &:hover {
        background-color: $CW2;
        text-decoration: none;
      }
      
    }
  }
}

.elements-list {
  h2 {
    text-align: center;
    margin-bottom: 0;
  }

  .pages {
    text-align: center;
    a{
      font-size:12px;
      display:inline-block;
      width: 20px;
      border-width: 1px 0 1px 1px;
      border-color: $CB14;
      background-color: #fff;
      border-style: solid;
      outline: none;

      &.active {
        pointer-events: none;
        background-color: $CB14;
        color: #fff;
      }

      &:last-child {
        border-width: 1px;
        border-radius: 0 4px 4px 0;
      }

      &:first-child {
        border-radius: 4px 0 0 4px;
      }

      &:hover {
        text-decoration: none;
        background-color: #eee;
      }
    }
  }

  .filters {
    text-align: center;
    margin-top: 20px;
    .search-field {
      display: inline-block;
      margin-bottom: 20px;
      vertical-align: top;
      font-size: 12px; 

      input {
        border: 1px solid #aaa;
        border-width: 0 0 1px 0;
        background-color: transparent;
        width: 200px;
      }
    }
    .fields-groups {
      display: inline-block;

      & > span {
        display: inline-block;
        vertical-align: top;
      }

      & > span > span {
        display: block;
        clear: both;
      }

      .title {
        font-size: 12px;
        font-weight: 300;
        width: 200px;
        text-align: right;
        display: inline-block;
      }

      select {
        font-size: 12px;
        border-radius: 3px;
        border: 1px solid #aaa;
        width: 200px;
        margin-bottom: 3px;
        outline: none;
        background: transparent;

        &:-moz-focusring {
          color: transparent;
          text-shadow: 0 0 0 #000;
        }
      }
    }
  }

  .columns-choice {
    text-align: center;
    display: inline-block;
    position: relative;
    left: 50%;
    width: 300px;
    margin-left: -150px;
    font-size: 12px;
    margin-bottom: 15px;
    
    ul {
      position: absolute;
      width: 300px;
      top: 20px;
      margin-top: 3px;
      list-style-type: none;
      text-align: left;
      display: inline-block;
      border: 1px solid #aaa;
      padding: 0 10px;
      background-color: #fff;
      z-index: 300;
      left: 0;

      li a {
        color: $CW8;
        display: inline-block;
        width: 100%;
        &:hover {
          text-decoration: none;
          color: #000;
        }
      }
    }
  }

  table.elements {
    width: auto;
    margin: 0 auto 100px;
    font-size: 13px;
    border: 1px solid #555;
    background-color: #fff;

    a {
      outline: none;
    }

    tbody {
      tr {
        // &:hover {
        //   background-color: #ddd;
        //   cursor: pointer;
        // }

        &:nth-child(2n) {
          background-color: #f5f5f5;
          // &:hover {
          //   background-color: #eee;
          // }
        }
      }
      
    }

    th {
      font-weight: 500;
      border-bottom: 1px solid #aaa;
      position: relative;
      padding: 0 20px;

      a[class^="move"] {
        font-size: 18px;
        position: absolute;
        top: calc(50% - 14px);

        &.move-left {
          left: 3px;
        }
        &.move-right {
          right: 3px;
        }
      }
    }
    td {
      padding: 2px 3px;
    }
    th, td {
      border-right: 1px solid #ccc;
      max-width: 350px;
      &:last-child {
        border-right: none;
      }
      &:first-child {
        border-right: none;
        padding: 0 0 0 2px;
        width: 20px;
        a {
          color: #000;
          text-decoration: none;
          font-size: 14px;
        }
      }
    }
  }
}

.config-form {
  table {
    font-size: 12px;
    margin: 0 auto;
    td, th {
      max-width: 800px;
      vertical-align: top;
      a {
        display: inline-block;
        padding: 2px 4px;
        border-radius: 2px;
        margin-left: 2px;
        &.remove-field {
          color:$CR89;
          background-color: $CR30;
          border: 1px solid $CR89;
        }
        &.add-field {
          color:$CG17;
          background-color: $CG12;
          border: 1px solid $CG17;
        }
      }
    }
  }
}

.bandeau-haut {
  text-align: center;
  height: 50px;
  border-bottom: 1px solid #333;
}

.loading-splash {
  text-align: right;
  position: fixed;
  bottom: 5px;
  right: 5px;
  width: 100px;
  z-index: 200;
  color: $CB37;
}

.modal-message {
  -webkit-transition: all .2s linear;
  transition: all .2s linear;
  padding-top: 80px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1200;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.20);

  & > div {
    background-color: #eee;
    width: 500px;
    margin: 0 auto;
    border-radius: 5px;

    .title {
      background-color: #555;
      color: #eee;
      border-radius: 5px 5px 0 0;
      padding: 5px 10px;
    }
    .message {
      padding: 5px 10px;
      font-size: 14px;
    }
    .buttons {
      text-align: right;
      padding: 5px 10px;
    }
  }
}

a.disabled {
  color: #888;
  pointer-events: none;
}

a.button {
  display: inline-block;
  background-color: $CW5;
  border-radius: 3px;
  padding: 0px 8px 2px;
  color: #fff;
  outline: none;
  font-size: 14px;
  transition: all .15s ease-in-out;
  position: relative;
  text-align: center;

  &.disabled {
    pointer-events: none;
    background-color: #ccc !important;
    color: #999 !important;
  }

  i {
    position: absolute;
    top: 5px;
    left: 8px;
  }

  &:hover {
    text-decoration: none;
  }

  &.small {
    font-size: 11px;
    padding: 2px 8px 2px;
  }

  &.grey {
    background-color: $CW5;
    &:hover {
      background-color: $CW4;
    }
  }

  &.red {
    background-color: $CR17;
    &:hover {
      background-color: darken($CR17, 10%);;
    }
  }

  &.green {
    background-color: $CYG09;
    &:hover {
      background-color: darken($CYG09, 10%);
    }
  }
  &.blue {
    background-color: $CB05;
    &:hover {
      background-color: darken($CB05, 10%);
    }
  }
  &.yellow {
    background-color: $CY38;
    &:hover {
      background-color: darken($CY38, 10%);
    }
  }
  &.violet {
    background-color: $CB69;
    &:hover {
      background-color: darken($CB69, 10%);
    }
  }
}

$inputWidth: 450px;

.table-form {
  margin: 0 auto;
  h2 {
    font-size: 20px;
    line-height: 22px;
    text-align: center;
  }

  h3 {
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    span {
      font-size: 14px;
      i {
        font-size: 17px;
      }
    }
  }

  h4 {
    border-bottom: 1px solid #555;
    margin-top: 15px;
  }

  td {
    padding: 3px 0px;

    &.label {
      text-align: right;
      font-size: 14px;
      font-weight: 300;
      padding-right: 5px;
      vertical-align: top;
      padding-top: 10px;
      .sous-label {
        font-size: 11px;
        color: #555;
        font-weight: 400;
      }
    }
  }


  a.a-check-active {
    color: #333;
    font-size: 13px;
    outline: none;
  }

  .invalid-message {
    color: $CR59;
    font-size: 11px;
  }

  .in-span {
    font-size: 14px;
    width: $inputWidth;
    display: inline-block;
  }

  select {
    font-size: 14px;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
    color: #495057;
    width: $inputWidth;
  }

  input[type="text"], textarea {
    width: $inputWidth;
    font-size: 14px;
    padding-top: .3rem;
    padding-bottom: .3rem;
    display: block;
    padding: .5rem .75rem;
    line-height: 1.25;
    color: #495057;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;

    &:focus {
      border: 1px solid $CB14;
    }
  }

  input[type="text"] {
    // height: 26px;
    line-height: 1.5;
  }

  .in-ac {
    // height: $formLineHeight;
    position: relative;
    input[type="text"] {
      width: $inputWidth;
    }
    .autoComplete-list {
      width: $inputWidth;
      position: absolute;
      left: 0;
      right: auto;
      box-shadow: 0px 0px 5px rgba(0,0,0,.25);
      li.autoComplete_result {
        margin: 0;
        font-size: 13px;
        color: #555;
        max-width: $inputWidth;
        padding: 10px 5px;
        cursor: pointer;
        margin-bottom: -1px;

        .autoComplete_highlighted {
          font-weight: 400;
          color: #000;
          
        }

        &:hover {
          color: #000;
          background-color: #eee;
        }
      }
    }
  }

  div.form-check {
    font-size: 14px;
    color: #333;
  }

  .in-multi {
    ul {
      padding: 0;
      margin: 10px 0 10px 5px;
      list-style-type: none;
      font-size: 14px;
      font-weight: 300;
      li {
        margin-bottom: 3px;
        i {
          cursor: pointer;
          margin-right: 15px;
          color: #555;
          &:hover {
            color: $CR59;
          }
        }
      }


    }
  }

  .in-binaire {
    i {
      cursor: pointer;
      color: #555;
    }
  }

  .in-li-static {
    list-style-type: none;
    margin: 0;
    padding:0;
    font-size: 14px;
  }
}

.multi-button {
  position: relative;
  div {
    background-color: $CB05;
    
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border-radius: 2px;
    a {
      color: #fff;
      display: block;
      width: 100%;
      &:hover {
        text-decoration: none;
        background-color: darken($CB05, 10%);
      }
    }
  }
}

.page-login {
  input {
    vertical-align: middle;
    height: 36px;
    box-sizing: border-box;
    font-size: 12px;
    width: 100%;
    border-radius: .5em;
    color: #444;
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0,0,0,0.04);
    padding: .6em 1.4em .5em .8em;
    margin-bottom: 5px;
}

  div.bg {
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    background-color: #05c0ff;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cpolygon fill='%23e57c0c' points='957 450 539 900 1396 900'/%3E%3Cpolygon fill='%23aa5c09' points='957 450 872.9 900 1396 900'/%3E%3Cpolygon fill='%23d88600' points='-60 900 398 662 816 900'/%3E%3Cpolygon fill='%239f6200' points='337 900 398 662 816 900'/%3E%3Cpolygon fill='%23c98e00' points='1203 546 1552 900 876 900'/%3E%3Cpolygon fill='%23936800' points='1203 546 1552 900 1162 900'/%3E%3Cpolygon fill='%23ba9600' points='641 695 886 900 367 900'/%3E%3Cpolygon fill='%23876d00' points='587 900 641 695 886 900'/%3E%3Cpolygon fill='%23aa9d00' points='1710 900 1401 632 1096 900'/%3E%3Cpolygon fill='%237b7100' points='1710 900 1401 632 1365 900'/%3E%3Cpolygon fill='%239aa300' points='1210 900 971 687 725 900'/%3E%3Cpolygon fill='%236e7400' points='943 900 1210 900 971 687'/%3E%3C/svg%3E");
    background-attachment: fixed;
    background-size: cover;
    z-index: 0;
  }
  & > div.form {
    position: relative;
    z-index: 10;
    box-sizing: border-box;
    width: 100%;
    max-width: 400px;
    margin: 50px auto 10px;
    background-color: #e57c0c;
    border-radius: 10px;
    padding: 25px;
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,.3);

    div.error {
      font-size: 13px;
      text-align: center;
      color: #fff;
      font-weight: 700;
      padding: 10px;
      margin: 10px 0;
      border-radius: 5px;
      background-color: darken($color: #e57c0c, $amount: 10);
    }

    div.label {
      color: #fff;
      padding-bottom: 8px;
    }

    input {
      font-size: 16px;
      &:focus {
        box-shadow: 0px 0px 8px 2px rgba(255,255,255,.8)
      }
    }


    a {
      box-shadow: 0px 0px 8px 2px rgba(0,0,0,.1);
      display: block;
      text-align: center;
      background-color: lighten($color: #e57c0c, $amount: 10);
      color: lighten($color: #e57c0c, $amount: 50);
      text-decoration: none;
      padding: 15px;
      border-radius: 5px;
      margin-top: 20px;
      font-weight: 700;
      font-size: 18px;
      transition: all .2s ease-in-out;

      &:hover {
        box-shadow: 0px 0px 8px 5px rgba(0,0,0,.1);
      }
    }

  }
}

.menu-top {
  ul {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 1.5em;
    font-weight: 300;
    display:flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 25px;

    // &:hover {
    //   a {
    //     color: rgba(249, 249, 249, .5);
    //     &:hover {
    //       color: rgba(249, 249, 249, 0.99);
    //     }
    //   }
    // }

    li {
      transition: color .5s, background .5s, height .5s;
      position: relative;

      span.label {
        position: absolute;
        top: 40px;
        width: 300px;
        left: 23px;
        margin-left: -150px;
        display: block;
        text-align: center;
        transition: opacity .5s;
        opacity: 0;
      }
      .icon {
        transition: color .5s, background .5s, height .5s;
        position: relative;
        padding: 1px 0 0 0;
        border-radius: 50%;
        display: inline-block;
        border: 1px solid #333;
        height: 37px;
        width: 37px;
        font-size: 22px;

        i {
          transform: translate3d(0, 0, 0);
        }
      }

      &:hover {
        span.label {
          opacity: 1;
        }
      }

      // background-color: $CB05;
      // &:hover { background-color: darken( $CB05, 10% ); }

      &.black {
        a { color:  $CN10; }
        .icon {  border-color: $CN10 !important; }
        &:hover {
          .icon { color: darken($CN10, 10%);  border-color: darken($CN10, 10%) !important;}
        }
      }
      &.grey {
        a { color:  $CW5; }
        .icon {  border-color: $CW5 !important; }
        &:hover {
          .icon { color: darken($CW5, 10%);  border-color: darken($CW5, 10%) !important;}
        }
      }
    
      &.red {
        a { color:  $CR37; }
        .icon {  border-color: $CR37 !important; }
        &:hover {
          .icon { color: darken($CR37, 10%);  border-color: darken($CR37, 10%) !important;}
        }
      }
    
      &.green {
        a { color:  $CYG09; }
        .icon {  border-color: $CYG09 !important; }
        &:hover {
          .icon { color: darken($CYG09, 10%);  border-color: darken($CYG09, 10%) !important;}
        }
      }
      &.blue {
        a { color:  $CB05; }
        .icon {  border-color: $CB05 !important; }
        &:hover {
          .icon { color: darken($CB05, 10%);  border-color: darken($CB05, 10%) !important;}
        }
      }
      &.yellow {
        a { color:  $CY28; }
        .icon {  border-color: $CY28 !important; }
        &:hover {
          .icon { color: darken($CY28, 10%);  border-color: darken($CY28, 10%) !important;}
        }
      }
      &.violet {
        a { color:  $CB69; }
        .icon {  border-color: $CB69 !important; }
        &:hover {
          .icon { color: darken($CB69, 10%);  border-color: darken($CB69, 10%) !important;}
        }
      }

      // min-width: 200px;
      &.small {
        min-width: auto !important;
        padding: 2px 6px 2px 5px;
        i {
          font-size: 20px;
        }
      }
      text-align: center;
      padding: 2px 5px;

      a {
        // width: 100%;
        display: block;
        // display: block;
        // color: rgba(249, 249, 249, .9);
        font-size: 16px;
        text-decoration: none;
        transition: color .5s, background .5s, height .5s;
      }
    }
  }
}